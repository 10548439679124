import AuthStore from '../store/AuthStore'
import { API_URI } from './constant'

let _TOKEN = ''
let key = ''

const fetcher = async (url, token = _TOKEN, method = 'GET', params = {}) => {
  if (url.indexOf('/games') !== 0) {
    //모든 라우트는 /games/.. 로 시작되도록 맞춰주세요!
    throw Error('api route is not available')
  }

  params = { ...params, key }
  if (!token) return
  const resource =
    method === 'GET' ? url + '?' + new URLSearchParams(params) : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init['method'] = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)
    const data = await res.json()
    if (data?.status === 409) {
      alert('서버와의 연결이 끊겼어요 재접속을 시도해주세요')
      window.location.href = '#closeGame'
      setTimeout(() => (window.location.href = 'about:blank'), 1000)
    } else if (data?.status === 429) {
      alert('비정상적인 계정 활동이 감지되어 올팜 이용이 제한됩니다.')
      window.location.href = '#closeGame'
      setTimeout(() => (window.location.href = 'about:blank'), 1000)
    }
    return data
  } catch (err) {
    return null
  }
}

const fetchWrapper = async (url, options) => {
  const updatedOptions = { ...options }
  if (options.token) {
    updatedOptions.headers = {
      ...updatedOptions.headers,
      'x-access-token': options.token,
    }
  } else if (AuthStore.token !== '') {
    updatedOptions.headers = {
      ...updatedOptions.headers,
      'x-access-token': AuthStore.token,
    }
  }
  return fetch(url, updatedOptions)
}

class BackendApis {
  setToken(value, setDuplicateLoginCheckDone) {
    _TOKEN = value
    key = Math.random().toString(36).slice(2, 10)
    //생성된 session key 를 user 업데이트, 만약 기존 session이 존재하면 기존기기는 로그아웃
    this.updateKey(key, setDuplicateLoginCheckDone)
    AuthStore.setKey(key)
    AuthStore.setToken(_TOKEN)
  }

  async updateKey(key, setDuplicateLoginCheckDone) {
    await fetcher('/games/alfarm/update-key', _TOKEN, 'PUT', {
      key,
    })
    setDuplicateLoginCheckDone(true)
  }

  async getAlfarmTrafficAvailability(token) {
    const result = await fetcher(
      '/games/traffic-availability',
      token,
      'GET',
      {},
    )
    return result
  }

  async startAlfarm(params) {
    const result = fetcher('/games/start-alfarm', _TOKEN, 'POST', params)
    return result
  }

  async setInvitorDefaultInviteInfo(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/set-invitor-default-invite-info',
      token,
      method,
      params,
    )
    return result
  }

  async getNow() {
    const result = fetcher('/games/now', _TOKEN, 'GET', {})
    return result
  }

  // 현재 시간 받아오기
  async getCurrentDate() {
    const result = fetcher('/games/alfarm-quest-list/now', _TOKEN, 'GET', {})
    return result
  }

  // 접속 보상
  // async questDailyLogInFromRBSheet(token, method = "PUT", params = {}) {
  //   const result = fetcher(
  //     "/games/alfarm-quest-list/complete/log-in",
  //     token,
  //     method,
  //     params
  //   );
  //   return result;
  // }

  // 비료 접속 보상
  async questDailyAttendanceFert(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-daily-fert/complete/log-in',
      token,
      method,
      params,
    )
    return result
  }

  // 접속 물 보상
  async questDailyLogInForWater(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-daily-water/complete/log-in',
      token,
      method,
      params,
    )
    return result
  }

  // 접속 물 최종일 보상
  async questDailyLogInForWaterFinalDate(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-daily-water/complete/final-date',
      token,
      method,
      params,
    )
    return result
  }

  // 접속 물 보상 V2
  async questDailyLogInForWaterNew(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/quest/daily-log-in-for-water-new',
      token,
      method,
      params,
    )
    return result
  }

  async questDailyLogInForWaterFinalDateNew(
    token,
    method = 'PUT',
    params = {},
  ) {
    const result = fetcher(
      '/games/quest/daily-log-in-for-water-final-date-new',
      token,
      method,
      params,
    )
    return result
  }

  async getWaterDailyAttendance(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-get-water/daily-attendance',
      token,
      method,
      params,
    )
    return result
  }

  async rewardAltoonMissionReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-get-water/altoon-mission',
      token,
      method,
      params,
    )
    return result
  }

  async rewardAltoonFertMissionReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-daily-fert/altoon-mission',
      token,
      method,
      params,
    )
    return result
  }

  async rewardDramaMissionReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-get-water/drama-mission',
      token,
      method,
      params,
    )
    return result
  }

  async rewardDramaFertMissionReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-daily-fert/drama-mission',
      token,
      method,
      params,
    )
    return result
  }

  async updateTenMinDealDailyAttendance(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-get-water/ten-min-deal-daily-attendance',
      token,
      method,
      params,
    )
    return result
  }

  // 아침 보상
  async addedWaterFromMorningQuest(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-quest-list/complete/time-log-in/morning',
      token,
      method,
      params,
    )
    return result
  }

  // 점심 보상
  async addedWaterFromNoonQuest(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-quest-list/complete/time-log-in/noon',
      token,
      method,
      params,
    )
    return result
  }

  // 저녁 보상
  async addedWaterFromNightQuest(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-quest-list/complete/time-log-in/night',
      token,
      method,
      params,
    )
    return result
  }

  // 물 연속 5일 보상
  async attendanceRewardFrom5DaysQuest(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/complete-water-quest/time-log-in/5days',
      token,
      method,
      params,
    )
    return result
  }

  // 3일 보상
  async addRewardFrom3DaysQuest(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-quest-list/complete/time-log-in/3days',
      token,
      method,
      params,
    )
    return result
  }

  // 7일 보상
  async addRewardFrom7DaysQuest(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-quest-list/complete/time-log-in/7days',
      token,
      method,
      params,
    )
    return result
  }

  // 15일 보상
  async addRewardFrom15DaysQuest(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/alfarm-quest-list/complete/time-log-in/15days',
      token,
      method,
      params,
    )
    return result
  }

  // 퀘스트 보상

  // 튜토리얼 완료
  async tutorialComplete(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/alfarm/tutorial-done', token, method, params)
    return result
  }

  // 농작물 수확 완료
  async finishedFarm(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/alfarm/farm-finished', token, method, params)
    return result
  }

  async getWater(token, method = 'POST', params = {}) {
    const result = fetcher('/games/get-water', token, method, params)
    return result
  }

  async recordEventLog(params = {}) {
    const result = fetcher('/games/event-log', _TOKEN, 'POST', params)
    return result
  }

  async newGiveWaterBasket(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/new-give-water-basket',
      token,
      method,
      params,
    )
    return result
  }

  async giveWaterBasket(token, method = 'POST', params = {}) {
    try {
      const result = await fetcher(
        '/games/give-water-basket',
        token,
        method,
        params,
      )
      return result
    } catch (err) {
      console.log(err)
    }
  }

  async giveWaterBasketFive(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/give-water-basket-five',
      token,
      method,
      params,
    )
    return result
  }

  async getBasicWater(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/games/get-basic-water',
      token,
      method,
      params,
    )
    return result
  }

  async initialWater(token, method = 'GET', params = {}) {
    const result = await fetcher('/games/initial-water', token, method, params)
    return result
  }

  async giveFert(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/give-fertilizer',
      token,
      method,
      params,
    )
    return result
  }

  async giveFertSet(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/give-fertilizer-set',
      token,
      method,
      params,
    )
    return result
  }

  async getLevel(token, method = 'GET', params = {}) {
    const result = await fetcher('/games/get-level', token, method, params)
    return result
  }

  async reSetDailyGiveWater(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/reset-daily-give-water',
      token,
      method,
      params,
    )
    return result
  }

  async setDailyGiveWaterZero(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/set-daily-give-water-zero',
      token,
      method,
      params,
    )
    return result
  }

  async getAlfarmReview(token, method = 'GET', params = {}) {
    const result = fetcher('/games/alfarm-review', token, method, params)
    return result
  }
  async getAlfarmReviewAll(token, method = 'GET', params = {}) {
    const result = fetcher('/games/alfarm-review-all', token, method, params)
    return result
  }

  async setLevelUpReward(token, method = 'POST', params = {}) {
    const result = fetcher('/games/alfarm-level-reward', token, method, params)
    return result
  }

  async getUserCodePushVersion(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/alfarm-user/code-push-version',
      token,
      method,
      params,
    )
    return result
  }

  async getUserDeviceType(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/alfarm-user/user-device-type',
      token,
      method,
      params,
    )
    return result
  }

  async getUserAppVersion(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/alfarm-user/app-version',
      token,
      method,
      params,
    )
    return result
  }

  async setYesterdayGiveWater(token, method = 'POST', params = {}) {
    const result = fetcher('/games/yesterday-give-water', token, method, params)
    return result
  }

  async setDailyWater(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/set-daily-water',
      token,
      method,
      params,
    )
    return result
  }

  // async setDailyPurchaseRewardStart(token, method = "POST", params = {}) {
  //   const result = fetcher(
  //     "/games/purchase-reward/daily",
  //     token,
  //     method,
  //     params
  //   );
  //   return result;
  // }

  async getDailyAlfarmPurchaseList(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/purchase-reward/daily-purchase-all-list',
      token,
      method,
      params,
    )
    return result
  }

  async getDailyPurchaseHistoryForAlfarm(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/purchase-reward/purchase-history',
      token,
      method,
      params,
    )
    return result
  }

  async getWeeklyPurchaseHistoryForAlfarm(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/purchase-reward/purchase-history-weekly',
      token,
      method,
      params,
    )
    return result
  }

  async setDailyPurchaseRewardFertClear(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/purchase-reward/daily-fert-clear',
      token,
      method,
      params,
    )
    return result
  }

  async getFertilizerBottomSheetInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/get-fertilizer-bottom-sheet-info',
      token,
      method,
      params,
    )
    return result
  }

  async getWaterBottomSheetInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/get-water-bottom-sheet-info',
      token,
      method,
      params,
    )
    return result
  }

  async getDailyPurchaseRewardWater(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/purchase-reward/daily-water-clear-history',
      token,
      method,
      params,
    )
    return result
  }

  async getWeeklyPurchaseRewardClear(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/purchase-reward/weekly-clear-history',
      token,
      method,
      params,
    )
    return result
  }

  async getDailyFertCheckedCount(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/daily-fert-checked-count',
      token,
      method,
      params,
    )
    return result
  }

  async setWeeklyPurchaseReward(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/purchase-reward/weekly-purchase-clear',
      token,
      method,
      params,
    )
    return result
  }

  async getGiveWaterCount(token, method = 'GET', params = {}) {
    const result = fetcher('/games/get-give-water-count', token, method, params)
    return result
  }

  async getDailyWaterCount(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/get-daily-water-count',
      token,
      method,
      params,
    )
    return result
  }

  async getGganbuList(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/gganbu/get-gganbu-list',
      token,
      method,
      params,
    )

    return result
  }

  async deleteGganbuFromList(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/gganbu/delete-gganbu-from-list',
      token,
      method,
      params,
    )
    return result
  }

  async getGganbuFarmInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/gganbu/get-gganbu-data`,
      token,
      method,
      params,
    )
    return result
  }

  async clearedItemGganbu(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/gganbu/cleared-item`,
      token,
      method,
      params,
    )
    return result
  }

  async giveWaterBasketToGganbu(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/gganbu/give-water-basket-to-gganbu',
      token,
      method,
      params,
    )
    return result
  }

  async giveWaterBasketFiveToGganbu(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/gganbu/give-water-basket-five-to-gganbu',
      token,
      method,
      params,
    )
    return result
  }

  async waterFromGganbu(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/gganbu/get-water-from-gganbu',
      token,
      method,
      params,
    )
    return result
  }

  async getLastGetWaterFromGganbu(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/gganbu/get-last-get-water-from-gganbu`,
      token,
      method,
      params,
    )
    return result
  }

  async getPhotoReviewItemsInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/water/photoreview`,
      token,
      method,
      params,
    )
    return result
  }

  async bonusRewardBox(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/gganbu/bonus-reward-box',
      token,
      method,
      params,
    )
    return result
  }

  async getBonusRewardInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/gganbu/get-bonus-reward-info`,
      token,
      method,
      params,
    )
    return result
  }

  async getInviteFriendCount(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/invite/get-invite-friend-count`,
      token,
      method,
      params,
    )
    return result
  }

  async getLastClearedInviteQuestTime(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/get-last-cleared-invite-quest-time`,
      token,
      method,
      params,
    )
    return result
  }

  async getDailyInviteInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/get-daily-invite-info`,
      token,
      method,
      params,
    )
    return result
  }

  async getDailyGiveWaterLevel(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/get-daily-give-water-level`,
      token,
      method,
      params,
    )
    return result
  }

  async sendMessageToGganbu(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/gganbu/send-message-to-gganbu',
      token,
      method,
      params,
    )
    return result
  }

  async getLastSendMessageAt(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/gganbu/get-last-send-message-at`,
      token,
      method,
      params,
    )
    return result
  }

  async getSocialHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/gganbu/get-social-history`,
      token,
      method,
      params,
    )
    return result
  }

  async getSpecificOngoingAlfarmInfo(params = {}) {
    const result = await fetcher(
      `/games/general/get-specific-ongoing-alfarm-info`,
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async clearedItem(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/badge/get-cleared-item`,
      token,
      method,
      params,
    )
    return result
  }

  async startLuckydraw(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/luckydraw/start-luckydraw',
      token,
      method,
      params,
    )
    return result
  }

  async getDrawCount(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/luckydraw/get-draw-count',
      token,
      method,
      params,
    )
    return result
  }
  async luckydrawPurchaseReward(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/luckydraw/luckydraw-purchase-reward',
      token,
      method,
      params,
    )
    return result
  }

  async getFertHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/history/get-fert-history`,
      token,
      method,
      params,
    )
    return result
  }
  async getWaterHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/history/get-water-history`,
      token,
      method,
      params,
    )
    return result
  }

  async getMinigameHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/history/get-minigame-history`,
      token,
      method,
      params,
    )
    return result
  }

  async getLuckydrawHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/history/get-luckydraw-history`,
      token,
      method,
      params,
    )
    return result
  }

  async getInviteHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/history/get-invite-history`,
      token,
      method,
      params,
    )
    return result
  }

  async setInviteHistorySuccessModalOpened(token, method = 'PUT', params = {}) {
    const result = await fetcher(
      `/games/history/set-invite-history-success-modal-opened`,
      token,
      method,
      params,
    )
    return result
  }

  async clearedTutorial(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/tutorial/cleared-tutorial',
      token,
      method,
      params,
    )
    return result
  }

  async puppyGift(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/puppy/puppy-gift',
      token,
      method,
      params,
    )
    return result
  }

  async lastGetPuppyGift(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/puppy/last-get-puppy-gift',
      token,
      method,
      params,
    )
    return result
  }

  async puppyEndAt(token, method = 'GET', params = {}) {
    const result = fetcher('/games/puppy/puppy-end-at', token, method, params)
    return result
  }

  async getPuppyPurchasedAt(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/puppy/get-puppy-purchased-at',
      token,
      method,
      params,
    )
    return result
  }

  async getAlfarmWateringViralInfo(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/viralEngines/alfarm/get-alfarm-watering-viral-info`,
      token,
      method,
      params,
    )
    return result
  }

  async getAlfarmWateringViralInfoByUserId(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/viralEngines/alfarm/get-alfarm-watering-viral-info-by-user-id`,
      token,
      method,
      params,
    )
    return result
  }

  async getAlfarmWateringUserInvitedInfos(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/viralEngines/alfarm/get-alfarm-watering-user-invited-infos`,
      token,
      method,
      params,
    )
    return result
  }

  async updateAlfarmWateringViralInfo(token, method = 'PUT', params = {}) {
    const result = await fetcher(
      `/viralEngines/alfarm/update-alfarm-watering-viral-info`,
      token,
      method,
      params,
    )
    return result
  }

  async updateAlfarmWateringViralGuestInfo(token, method = 'PUT', params = {}) {
    const result = await fetcher(
      `/viralEngines/alfarm/update-alfarm-watering-viral-guest-info`,
      token,
      method,
      params,
    )
    return result
  }

  async newUserCheckIn(token, method = 'POST', params = {}) {
    const result = fetcher('/games/new-user/check-in', token, method, params)
    return result
  }

  async checkInData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/new-user/check-in-data',
      token,
      method,
      params,
    )
    return result
  }

  async dailyCheckInFertilizerQuestData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/fert/daily-check-in-fertilizer-quest-data',
      token,
      method,
      params,
    )
    return result
  }

  // async appsFlyerCheck(token, method = "POST", params = {}) {
  //   const result = fetcher("/games/apps-flyer/check", token, method, params);
  //   return result;
  // }

  async clearedInviteGganbuQuest(token, method = 'POST', params) {
    const result = await fetcher(
      '/games/water/cleared-invite-gganbu-quest',
      token,
      method,
      params,
    )

    return result
  }

  async clearedInviteGganbuQuestFert(token, method = 'POST', params) {
    const result = await fetcher(
      '/games/water/cleared-invite-gganbu-quest-fert',
      token,
      method,
      params,
    )

    return result
  }

  async getChangePlantData(token, method = 'GET', params) {
    const result = await fetcher(
      '/games/changePlant/get-change-plant-data',
      token,
      method,
      params,
    )

    return result
  }

  async turnOffChangePlantData(token, method = 'PUT', params) {
    const result = await fetcher(
      '/games/changePlant/turn-off-change-plant-data',
      token,
      method,
      params,
    )
    return result
  }

  async turnOnChangePlantData(token, method = 'PUT', params) {
    const result = await fetcher(
      '/games/changePlant/turn-on-change-plant-data',
      token,
      method,
      params,
    )
    return result
  }
  async changePlant(token, method = 'PUT', params) {
    const result = await fetcher(
      '/games/changePlant/change-plant',
      token,
      method,
      params,
    )
    return result
  }
  async getChangePlantLog(token, method = 'GET', params) {
    const result = await fetcher(
      '/games/changePlant/get-change-plant-log',
      token,
      method,
      params,
    )
    return result
  }

  async getDidCheckNewFeed(token, method = 'GET', params) {
    const result = await fetcher(
      '/games/get-did-check-new-feed',
      token,
      method,
      params,
    )
    return result
  }

  async altoonWaterMissionData(token, method = 'GET', params) {
    const result = await fetcher(
      '/games/altoon-mission-data',
      token,
      method,
      params,
    )
    return result
  }

  async altoonFertMissionData(token, method = 'GET', params) {
    const result = await fetcher(
      '/games/altoon-fert-mission-data',
      token,
      method,
      params,
    )
    return result
  }

  async dramaWaterMissionData(token, method = 'GET', params) {
    const result = await fetcher(
      '/games/drama-mission-data',
      token,
      method,
      params,
    )
    return result
  }

  async dramaFertMissionData(token, method = 'GET', params) {
    const result = await fetcher(
      '/games/drama-fert-mission-data',
      token,
      method,
      params,
    )
    return result
  }

  async lastPlayedAt(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/comeback-user/last-played-at',
      token,
      method,
      params,
    )

    return result
  }

  async checkIn(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/comeback-user/check-in',
      token,
      method,
      params,
    )
    return result
  }

  async setDidCheckNewFeedTrue(token, method = 'PUT', params) {
    const result = await fetcher(
      '/games/set-did-check-new-feed-true',
      token,
      method,
      params,
    )
    return result
  }

  async comebackUserCheckInData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/comeback-user/check-in-data',
      token,
      method,
      params,
    )
    return result
  }

  async checkOrderCount(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/select-farm/check-order-count',
      token,
      method,
      params,
    )
    return result
  }

  async checkGameCount(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/select-farm/check-game-count',
      token,
      method,
      params,
    )
    return result
  }

  async clearReviewCheckQuest(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/quest/clear-review-check',
      token,
      method,
      params,
    )
    return result
  }

  async photoReviewReward(token, method = 'POST', params = {}) {
    const result = fetcher('/games/photo-review/reward', token, method, params)
    return result
  }

  async checkReviewQuest(token, method = 'GET', params = {}) {
    const result = fetcher('/games/quest/check-reivew', token, method, params)
    return result
  }

  async waterFromAlwayzFarm(token, method = 'POST', params = {}) {
    const result = fetcher('/games/alwayz-farm/water', token, method, params)
    return result
  }

  async giveWaterBasketToAlwayzFarm(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/alwayz-farm/give-water-basket',
      token,
      method,
      params,
    )
    return result
  }

  async bonusRewardBoxFromAlwayzFarm(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/alwayz-farm/bonus-reward-box',
      token,
      method,
      params,
    )
    return result
  }

  async getAlFarmSecretScratchHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/secret/get-secret-scratch-hisotry',
      token,
      method,
      params,
    )

    return result
  }

  async getLastMinuteInviteInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/get-last-minute-invite',
      token,
      method,
      params,
    )

    return result
  }

  async setLastMinuteInviteInfo(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/last-minute-invite-init',
      token,
      method,
      params,
    )

    return result
  }

  async setLevelUpChanceInviteInfo(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/level-up-chance-invite-info',
      token,
      method,
      params,
    )

    return result
  }

  async checkCherryPickUser(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/cherry-pick/check-user',
      token,
      method,
      params,
    )
    return result
  }
  async waterToFertilizer(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/water-to-fertilizer/change',
      token,
      method,
      params,
    )
    return result
  }
  async getTodayDailyLotteryData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/daily-lottery/get-daily-lottery-data',
      token,
      method,
      params,
    )
    return result
  }

  async getDailyLotteryInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/daily-lottery/get-daily-lottery-info',
      token,
      method,
      params,
    )
    return result
  }
  async giveDailyLotteryPrize(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/daily-lottery/give-daily-lottery-prize',
      token,
      method,
      params,
    )
    return result
  }
  async getOngoingWaterBattleData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/water-battle/ongoing-data',
      token,
      method,
      params,
    )
    return result
  }

  async endsBattle(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/water-battle/ends-battle',
      token,
      method,
      params,
    )
    return result
  }

  async battlingGiveWaterBasket(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/water-battle/battling-give-water-basket',
      token,
      method,
      params,
    )
    return result
  }

  async battlingGiveWaterBasketFive(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/water-battle/battling-give-water-basket-five',
      token,
      method,
      params,
    )
    return result
  }
  async newBattlingGiveWaterBasket(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/water-battle/new-battling-give-water-basket',
      token,
      method,
      params,
    )
    return result
  }
  async autoOpponentGiveWater(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/water-battle/auto-opponent-give-water',
      token,
      method,
      params,
    )
    return result
  }

  async autoOpponentGiveWaterEnter(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/water-battle/auto-opponent-give-water-enter',
      token,
      method,
      params,
    )
    return result
  }

  async novFastHarvestClear(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/nov-fast-harvest/clear',
      token,
      method,
      params,
    )
    return result
  }

  async novFastHarvestData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/nov-fast-harvest/user-data',
      token,
      method,
      params,
    )
    return result
  }

  async getAlfarmCommonDataDailyLotteryToggle(
    token,
    method = 'GET',
    params = {},
  ) {
    const result = fetcher(
      '/games/common-data/get-daily-lottery-toggle',
      token,
      method,
      params,
    )
    return result
  }

  async getAlfarmCommonDataDailyWaterRewardToggle(
    token,
    method = 'GET',
    params = {},
  ) {
    const result = fetcher(
      '/games/common-data/get-daily-water-reward-toggle',
      token,
      method,
      params,
    )
    return result
  }

  async getAlfarmSingleItemDealInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/common-data/get-alfarm-single-deal-toggle',
      token,
      method,
      params,
    )
    return result
  }

  async checkUserEventParticipated(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/alfarm-single-deal/get-participated-log',
      token,
      method,
      params,
    )
    return result
  }

  async detectLastMinuteInviteParticipant(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/detect-last-minute-invite-participant',
      token,
      method,
      params,
    )
    return result
  }

  async detectLevelUpChanceInviteParticipant(
    token,
    method = 'GET',
    params = {},
  ) {
    const result = fetcher(
      '/games/detect-level-up-chance-invite-participant',
      token,
      method,
      params,
    )
    return result
  }

  async giveWaterBombReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/minigame/give-water-bomb-reward',
      token,
      method,
      params,
    )
    return result
  }

  async giveMinigameCompleteRewardWaterBomb(
    token,
    method = 'PUT',
    params = {},
  ) {
    const result = fetcher(
      '/games/minigame/give-minigame-complete-reward-water-bomb',
      token,
      method,
      params,
    )
    return result
  }
  async giveMinigameCompleteRewardLuckyDraw(
    token,
    method = 'PUT',
    params = {},
  ) {
    const result = fetcher(
      '/games/minigame/give-minigame-complete-reward-lucky-draw',
      token,
      method,
      params,
    )
    return result
  }
  async giveMinigameCompleteRewardLotteryDraw(
    token,
    method = 'PUT',
    params = {},
  ) {
    const result = fetcher(
      '/games/minigame/give-minigame-complete-reward-lottery-draw',
      token,
      method,
      params,
    )
    return result
  }
  async giveLotteryDrawReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/minigame/give-lottery-draw-reward',
      token,
      method,
      params,
    )
    return result
  }

  async setWaterBombLastPlayedAt(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/minigame/set-water-bomb-last-played-at',
      token,
      method,
      params,
    )
    return result
  }

  async getWaterBombLastPlayedAt(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/minigame/get-water-bomb-last-played-at',
      token,
      method,
      params,
    )
    return result
  }

  async getMinigameInfo(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/minigame/get-minigame-info',
      token,
      method,
      params,
    )
    return result
  }

  async gganbuPurchaseCount(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/gganbu/purchase-count',
      token,
      method,
      params,
    )
    return result
  }

  async waterForProduceFertMachine(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/produce-fert-machine/water-for-produce-fert-machine',
      token,
      method,
      params,
    )
    return result
  }

  async setAlfarmSingleDealModalOpenedLog(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/alfarm-single-deal/log-modal-opened',
      token,
      method,
      params,
    )
    return result
  }

  async receiveProducedFertFromMachine(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/produce-fert-machine/receive-fert',
      token,
      method,
      params,
    )
    return result
  }

  async checkUserSingleDealModalOpened(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/alfarm-single-deal/get-modal-opened-log',
      token,
      method,
      params,
    )
    return result
  }

  async getAlFarmProduceFertMachineHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/produce-fert-machine/history',
      token,
      method,
      params,
    )
    return result
  }

  async checkRouletteEventCouponCount(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/roulette-event/check-coupon-count',
      token,
      method,
      params,
    )
    return result
  }

  async startRoulette(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/roulette-event/start-roulette',
      token,
      method,
      params,
    )
    return result
  }

  async dealEventRoulette(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/deal-event/start-roulette',
      token,
      method,
      params,
    )
    return result
  }

  async getRouletteEventHistory(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/games/roulette-event/history',
      token,
      method,
      params,
    )
    return result
  }

  async getAnnouncementList(token, method = 'GET', params = {}) {
    const result = await fetcher(
      `/games/announcement/get-announcement-list`,
      token,
      method,
      params,
    )
    return result
  }

  async setUserAnnouncementNumber(token, method = 'PUT', params = {}) {
    const result = await fetcher(
      `/games/announcement/set-user-announcement-number`,
      token,
      method,
      params,
    )

    return result
  }

  async setLastRewardAnnouncementNumber(token, method = 'PUT', params = {}) {
    const result = await fetcher(
      `/games/announcement/set-last-reward-announcement-number`,
      token,
      method,
      params,
    )

    return result
  }
  async getAnnouncementReward(params = {}) {
    const result = await fetcher(
      `/games/announcement/get-announcement-reward`,
      _TOKEN,
      'PUT',
      params,
    )

    return result
  }

  async checkNewUser(token, method = 'GET', params = {}) {
    const result = await fetcher(`/games/check-new-user`, token, method, params)
    return result
  }

  async updatelastUpdatedAt(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/update/update-date', token, method, params)
    return result
  }

  async updateSpecificField(params = {}) {
    const result = fetcher(
      '/games/update/update-specific-field',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async increaseSpecificField(params = {}) {
    const result = fetcher(
      '/games/update/increase-specific-field',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async giveSearchMissionReward(params = {}) {
    const result = fetcher(
      '/games/give-search-mission-reward',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async giveSurpriseMissionReward(params = {}) {
    const result = fetcher(
      '/games/surprise-mission/give-reward',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async giveReward(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/update/give-reward', token, method, params)
    return result
  }

  async giveRewardForSanSinEvent(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/sansin-event/give-reward',
      token,
      method,
      params,
    )
    return result
  }

  async giveDailyMissionReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/daily-mission/give-reward',
      token,
      method,
      params,
    )
    return result
  }

  async giveDailyMissionv2Reward(token, method = 'PUT', params = {}) {
    let type
    if (type === 'giveDailyMissionv2') return
    type = 'giveDailyMissionv2'
    const result = fetcher(
      '/games/daily-mission-v2/clear',
      token,
      method,
      params,
    )
    setTimeout(() => {
      type = false
    }, 500)
    return result
  }

  async logABclick(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/log-ab-click', token, method, params)
    return result
  }

  async consecutiveAttendanceChecker(params) {
    const result = fetcher(
      '/games/timeCheck/consecutive-attendance-checker',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async getInitModal(params = {}) {
    const result = fetcher('/games/modal', _TOKEN, 'GET', params)
    return result
  }

  async recordModalOpened(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/modal-opened', token, method, params)
    return result
  }

  // 룰렛 리턴 시작
  async startRouletteEvent(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/roulette-event-v2/start',
      token,
      method,
      params,
    )
    return result
  }

  async updateRouletteTime(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/roulette-event-v2/roulette-time',
      token,
      method,
      params,
    )
    return result
  }

  async updateRouletteNumber(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/roulette-event-v2/roulette-number',
      token,
      method,
      params,
    )
    return result
  }

  async checkRouletteCouponCount(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/roulette-event-v2/check-coupon-count',
      token,
      method,
      params,
    )
    return result
  }

  async startRouletteV2(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/roulette-event-v2/start-roulette',
      token,
      method,
      params,
    )
    return result
  }

  async getRouletteEventV2History(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/roulette-event-v2/history',
      token,
      method,
      params,
    )
    return result
  }
  // 룰렛 리턴 끝

  // 쿠폰 api 시작

  async getUserAlfarmCoupons(params = {}) {
    const result = fetcher(
      '/games/coupon/get-user-alfarm-coupons',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async getUserSellerCoupons(params = {}) {
    const result = fetcher(
      '/games/coupon/get-user-seller-coupons',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async getSellerBasicInfo(params = {}) {
    try {
      const result = await fetcher(
        `/games/user-sellers-info`,
        _TOKEN,
        'GET',
        params,
      )
      return result
    } catch (err) {
      console.log(err)
    }
  }
  // 쿠폰 api 끝

  // 신규 보물상자 퀘스트 api 시작
  async checkTreasureBoxStatus(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/treasure-box/find-user-data',
      token,
      method,
      params,
    )
    return result
  }

  async openNewbieTreasureBox(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/treasure-box/open-newbie-treasure-box',
      token,
      method,
      params,
    )
    return result
  }

  // 신규 보물상자 퀘스트 api 끝

  async getUserId(token, method = 'GET', params = {}) {
    const result = fetcher('/games/get-user-id', token, method, params)
    return result
  }

  async updateMyItemName(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/update-my-item-name', token, method, params)
    return result
  }

  // 캡슐 이벤트

  async drawFirstMachine(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/capsule-event/draw-first-machine',
      token,
      method,
      params,
    )
    return result
  }

  async drawSecondMachine(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/capsule-event/draw-second-machine',
      token,
      method,
      params,
    )
    return result
  }

  async getMachineData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/capsule-event/get-machine-data',
      token,
      method,
      params,
    )
    return result
  }
  async checkCapsuleCoupon(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/capsule-event/check-capsule-coupon',
      token,
      method,
      params,
    )
    return result
  }
  async getCapsuleDrawHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/capsule-event/draw-history',
      token,
      method,
      params,
    )
    return result
  }

  // 복돼지 키우기 스타트

  async startLuckyPiggy(params) {
    const result = fetcher('/games/lucky-piggy/start', _TOKEN, 'POST', params)
    return result
  }

  async giveCoin(params) {
    const result = await fetcher(
      '/games/lucky-piggy/give-coin',
      _TOKEN,
      'POST',
      params,
    )
    return result
  }

  async luckyPiggyEnd(params) {
    const result = await fetcher(
      '/games/lucky-piggy/end',
      _TOKEN,
      'POST',
      params,
    )
    return result
  }

  async openLuckyPiggyBox(params) {
    const result = await fetcher(
      '/games/lucky-piggy/open-box',
      _TOKEN,
      'POST',
      params,
    )
    return result
  }

  // /games/.. 라우트 형식만 api로 인식하도록 변경함
  // async getBankList(token, method = "GET", params = {}) {
  //   const result = fetcher("/sellers/banks/total", token, method, params);
  //   return result;
  // }

  async requestLuckyPiggyWithdraw(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/lucky-piggy/request-lucky-piggy-withdraw',
      token,
      method,
      params,
    )
    return result
  }

  async luckyPiggyInviteHistory(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/lucky-piggy/invite-history',
      token,
      method,
      params,
    )
    return result
  }

  async checkOngoingLuckyPiggyData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/lucky-piggy/check-ongoing-lucky-piggy-data',
      token,
      method,
      params,
    )
    return result
  }

  async clearedFirstQuest(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/lucky-piggy/cleared-first-quest',
      token,
      method,
      params,
    )
    return result
  }

  //복돼지 키우기 엔드

  // 플래피버드 시작'

  async reduceWaterByPlay(params = {}) {
    const result = fetcher(
      '/games/flappy-bird/reduce-water-by-play',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async pushFlappyBirdLog(params = {}) {
    const result = fetcher(
      '/games/flappy-bird/push-flappy-bird-log',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async giveFlappyBirdReward(params = {}) {
    const result = fetcher(
      '/games/flappy-bird/give-flappy-bird-reward',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async updateFlappyBirdPurchaseReward(params = {}) {
    const result = fetcher(
      '/games/flappy-bird/update-flappy-bird-purchase-reward',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  //플래피버드 끝

  //초대 코드 시작
  async referralCodeProcess(params = {}) {
    const result = fetcher(
      '/games/referral-code/process-referral-code-submit',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }
  //초대 코드 끝

  //보따리 시작
  async initAffiliationData(params = {}) {
    const result = fetcher(
      '/games/affiliation/init-data',
      _TOKEN,
      'POST',
      params,
    )
    return result
  }
  async getAffiliationData(params = {}) {
    const result = fetcher('/games/affiliation/get-data', _TOKEN, 'GET', params)
    return result
  }

  async purchaseAffiliationItem(params = {}) {
    const result = fetcher(
      '/games/affiliation/purchase-item',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }
  //보따리 끝
  // 샤인머스캣 grape 시작
  async startSeasonalEventRoulette(token, method = 'POST', params = {}) {
    const result = fetcher(
      '/games/seasonal-event/start-roulette',
      token,
      method,
      params,
    )
    return result
  }

  async initSeasonalEventData(params = {}) {
    const result = fetcher(
      '/games/seasonal-event/init-data',
      _TOKEN,
      'POST',
      params,
    )
    return result
  }

  async getSeasonalEventData(params = {}) {
    const result = fetcher(
      '/games/seasonal-event/get-data',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async setInitialButtonClickFertSet(params = {}) {
    const result = fetcher(
      '/games/coffee-gift/set-initial-button-click-fert-set',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  // 샤인머스캣 grape 끝

  //커피선물 시작

  async initCoffeeGiftData(params = {}) {
    const result = fetcher(
      '/games/coffee-gift/init-data',
      _TOKEN,
      'POST',
      params,
    )
    return result
  }
  async getCoffeeGiftData(params = {}) {
    const result = fetcher('/games/coffee-gift/get-data', _TOKEN, 'GET', params)
    return result
  }

  //커피선물 끝

  //초코선물 시작

  async initChocoGiftData(params = {}) {
    const result = fetcher(
      '/games/choco-gift/init-data',
      _TOKEN,
      'POST',
      params,
    )
    return result
  }
  async getChocoGiftData(params = {}) {
    const result = fetcher('/games/choco-gift/get-data', _TOKEN, 'GET', params)
    return result
  }

  async setInitialChocoButtonClickFertSet(params = {}) {
    const result = fetcher(
      '/games/choco-gift/set-initial-button-click-fert-set',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  //초코선물 끝

  async updateUserButtonClickInfo(params = {}) {
    const result = await fetcher(
      '/games/user-button-click',
      _TOKEN,
      'PUT',
      params,
    )

    return result
  }

  async getStandardTimeInfo() {
    const result = fetcher(
      '/games/time/get-standard-time-info',
      _TOKEN,
      'GET',
      {},
    )
    return result
  }

  async getSpeechBubbleMent(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/get-speech-bubble-ment',
      token,
      method,
      params,
    )
    return result
  }

  async getUserBasicInfo(token, method = 'GET', params = {}) {
    const result = fetcher('/games/get-user-basic-info', token, method, params)
    return result
  }

  async getUserBasicInfobyUID(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/get-user-basic-info-by-uid',
      token,
      method,
      params,
    )
    return result
  }

  async getGganbuBok(token, method = 'GET', params = {}) {
    const result = fetcher('/games/get-gganbu-bok', token, method, params)
    return result
  }

  async sendBokAlarm(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/send-bok-alarm', token, method, params)
    return result
  }

  async checkDailyInvite(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/daily-invite/check-user-data',
      token,
      method,
      params,
    )
    return result
  }

  async clearedInviteQuest(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/cleared-invite-quest', token, method, params)
    return result
  }

  async updateUserAlfarmPushSetting(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/update/alfarm-user-push-setting',
      token,
      method,
      params,
    )
    return result
  }

  async getDailyMissionCheckData(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/daily-mission/get-check-data',
      token,
      method,
      params,
    )
    return result
  }

  async checkVipCardData(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/games/vip-card/check-data',
      token,
      method,
      params,
    )
    return result
  }

  async getDailyMissionInfov2(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/daily-mission-v2/info',
      token,
      method,
      params,
    )
    return result
  }

  async vipCardFertSet(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/vip-card/fert-set', token, method, params)
    return result
  }

  async changePlantAmount(token, method = 'POST', params = {}) {
    const result = fetcher('/games/change-plant-amount', token, method, params)
    return result
  }

  async checkEngagementNutrimentType() {
    const result = fetcher(
      '/games/check-engagement-nutriment-type',
      _TOKEN,
      'GET',
      {},
    )
    return result
  }

  async giveEngagementNutrimentReward() {
    const result = fetcher(
      '/games/give-engagement-nutriment-reward',
      _TOKEN,
      'PUT',
      {},
    )
    return result
  }

  // 니편내편 시작

  async getSidepickUserData(params = {}) {
    const result = fetcher(
      '/games/sidepick/get-user-data',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async getSidepickRankByScore(params = {}) {
    const result = fetcher(
      '/games/sidepick/get-rank-by-score',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async getSidepickGganbuRanks() {
    const result = fetcher(
      '/games/sidepick/get-gganbu-ranks',
      _TOKEN,
      'GET',
      {},
    )
    return result
  }

  async getSidepickTopRanks() {
    const result = fetcher('/games/sidepick/get-top-ranks', _TOKEN, 'GET', {})
    return result
  }

  async recordSidepickData(params = {}) {
    const result = fetcher('/games/sidepick/record-data', _TOKEN, 'PUT', params)
    return result
  }

  async setUserAlfarmNotificationSettingToTrue() {
    const result = fetcher(
      '/games/sidepick/set-pushsetting-to-true',
      _TOKEN,
      'PUT',
      {},
    )
    return result
  }

  // 니편내편 끝

  async getTimeInfo() {
    const result = await fetcher('/games/util/get-time-info', _TOKEN, 'GET', {})
    return result
  }

  async changePlantBeforeChurn(params = {}) {
    const result = fetcher(
      '/games/change-plant-before-churn',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async drinkWaterButton() {
    const result = fetcher('/games/drink-water/button', _TOKEN, 'PUT', {})
    return result
  }

  async alranchMission(type) {
    const result = fetcher('/games/alranch-mission', _TOKEN, 'PUT', {
      type: type,
    })
    return result
  }

  async newyearGiftModal(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/newyear-gift/reward', token, method, params)
    return result
  }

  async getFriendsList(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/phone-book-gganbu/friends-list',
      token,
      method,
      params,
    )
    return result
  }

  async checkInviteReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/phone-book-gganbu/check-invite-reward',
      token,
      method,
      params,
    )
    return result
  }

  async deleteContactList(params = {}) {
    const result = fetcher(
      '/games/phone-book/delete-contact-list',
      _TOKEN,
      'DELETE',
      params,
    )
    return result
  }

  async sendResurrectAlarm(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/send-resurrect-alarm', token, method, params)
    return result
  }

  async pokeFriend(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/poke-friend', token, method, params)
    return result
  }

  async getPokeData(token, method = 'GET', params = {}) {
    const result = fetcher('/games/poke-friend/data', token, method, params)
    return result
  }

  async getQuotes(token, method = 'GET', params = {}) {
    const result = fetcher('/games/quote', token, method, params)
    return result
  }

  async updateLastVisitedGganbu(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/gganbu/last-visited-gganbu',
      token,
      method,
      params,
    )
    return result
  }

  async gganbuGift(token, method = 'POST', params = {}) {
    const result = await fetcher('/games/gganbu/gift', token, method, params)
    return result
  }

  async agreePushNotification(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/agree-push-notification',
      token,
      method,
      params,
    )
    return result
  }

  async checkRouletteInvite(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/roulette/check-user-data',
      token,
      method,
      params,
    )
    return result
  }

  async dealAutomation(method = 'GET', params = {}) {
    const result = fetcher(
      '/games/common-data/deal-automation',
      _TOKEN,
      method,
      params,
    )
    return result
  }

  async clearedPurchasedCheckInReward(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/purchase-check-in-reward/cleared',
      token,
      method,
      params,
    )
    return result
  }

  async setLastHarvestPurchaseInit(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/last-harvest-purchase/init',
      token,
      method,
      params,
    )
    return result
  }

  async getQuiz(token, method = 'GET', params = {}) {
    const result = fetcher('/games/quiz/data', token, method, params)
    return result
  }

  async getQuizV2(token, method = 'GET', params = {}) {
    const result = fetcher('/games/quiz/data/v2', token, method, params)
    return result
  }

  async getQuizListByQuizIds(token, method = 'GET', params = {}) {
    const result = fetcher('/games/quiz/quiz-list-by-id', token, method, params)
    return result
  }

  async postAnswer(token, method = 'POST', params = {}) {
    const result = await fetcher('/games/quiz/answer', token, method, params)
    return result
  }

  async postAnswerV2(token, method = 'POST', params = {}) {
    const result = await fetcher('/games/quiz/answer/v2', token, method, params)
    return result
  }

  async postAnswerV3(token, method = 'POST', params = {}) {
    const result = await fetcher('/games/quiz/answer/v3', token, method, params)
    return result
  }

  async getQuizByLocalStorage(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/quiz/local-storage-data',
      token,
      method,
      params,
    )
    return result
  }

  async getStoreByLocalStorage(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/store/local-storage-data',
      token,
      method,
      params,
    )
    return result
  }

  async getStoreByAdId(token, method = 'GET', params = {}) {
    const result = fetcher('/games/seller-farm/store', token, method, params)
    return result
  }

  async getStoreByAdIdList(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/seller-farm/store/id-list',
      token,
      method,
      params,
    )
    return result
  }

  async getFetilizerAdItem(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/ad-item-browsing-quest/item',
      token,
      method,
      params,
    )
    return result
  }

  async getAdItem(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/ad-item-browsing-quest/ad-item',
      token,
      method,
      params,
    )
    return result
  }

  async friendGardenData(token, method = 'GET', params = {}) {
    const result = fetcher('/games/friend-garden/data', token, method, params)
    return result
  }

  async givefriendGardenReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/friend-garden/give-reward',
      token,
      method,
      params,
    )
    return result
  }

  async getBrowsingAdItem(token, method = 'GET', params = {}) {
    try {
      const options = {
        token,
        method,
        headers: { 'Content-Type': 'application/json' },
      }
      const BACK_URI = 'https://alwayzshop.ilevit.com'
      const res = await fetchWrapper(
        `${BACK_URI}/items/ad-item-browsing-quest`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  ///
  /// 숏폼샵 a/b/c 테스트
  ///
  async getBrowsingAdItemForShortFormShop(token, method = 'GET', params = {}) {
    try {
      const options = {
        token,
        method,
        headers: { 'Content-Type': 'application/json' },
        params,
      }
      const BACK_URI = 'https://alwayzshop.ilevit.com'
      const res = await fetchWrapper(
        `${BACK_URI}/items/ad-item-browsing-quest/short-form-shop`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  ///
  /// 숏폼샵 a/b/c 테스트
  ///
  async getShortFormVideoAdItem(token, method = 'GET', params = {}) {
    try {
      const options = {
        token,
        method,
        headers: { 'Content-Type': 'application/json' },
        params,
      }
      const BACK_URI = 'https://alwayzshop.ilevit.com'
      const res = await fetchWrapper(
        `${BACK_URI}/shortFormShop/post-item-by-item-id`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async logEventShortFormShop(token, method = 'PUT', params = {}) {
    try {
      const options = {
        token,
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      }
      const BACK_URI = 'https://alwayzshop.ilevit.com'
      const res = await fetchWrapper(
        `${BACK_URI}/shortFormShop/event-log`,
        options,
      )
      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async giveShortFormVideoAdReward(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games//alfarm-quest-list/complete-browsing-ad-item',
      token,
      method,
      params,
    )
    return result
  }

  async getLastestBrowsedAdItem(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/ad-item-browsing-quest/lastest-browsed-ad-item',
      token,
      method,
      params,
    )
    return result
  }

  async getSellerFarmList(token, method = 'GET', params = {}) {
    const result = fetcher('/games/seller-farm/list', token, method, params)
    return result
  }

  async getSellerFarmListForTripleExposure(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/seller-farm/list/triple-exposure',
      token,
      method,
      params,
    )
    return result
  }

  async getLastVisitedSellerStoreId(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/last-visited-seller-store',
      token,
      method,
      params,
    )
    return result
  }

  async waterFromSellerFarm(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/seller-farm/water',
      token,
      method,
      params,
    )
    return result
  }

  async waterFromSellerFarmForTripleExposure(
    token,
    method = 'POST',
    params = {},
  ) {
    const result = await fetcher(
      '/games/seller-farm/water/triple-exposure',
      token,
      method,
      params,
    )
    return result
  }

  async setCherryPickerCoupon(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/cherry-pick/coupon',
      token,
      method,
      params,
    )
    return result
  }

  async getItemInfo(token, method = 'GET', params = {}) {
    const result = await fetcher('/games/item-info', token, method, params)
    return result
  }

  async guessMe(guessMeIdx) {
    const result = await fetcher('/games/guess-me', _TOKEN, 'GET', {
      guessMeIdx,
    })
    return result
  }

  async sendNotification(params) {
    const result = await fetcher(
      '/games/oneSignal/send-notification',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async submitGuessMe(params) {
    const result = await fetcher(
      '/games/guess-me/submit',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async runPurchaseRoulette(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/purchase-roulette/run',
      token,
      method,
      params,
    )
    return result
  }

  async setPersonalizedShopRewardData(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/personalized-shop/reward-data',
      token,
      method,
      params,
    )
    return result
  }

  async getDailyGiveWater(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/daily-give-water/water',
      token,
      method,
      params,
    )
    return result
  }

  async trendQuiz(params = {}) {
    const result = fetcher('/games/trend-quiz/quiz', _TOKEN, 'GET', params)
    return result
  }

  async trendQuizSubmitAnswer(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/trend-quiz/submit', token, method, params)
    return result
  }

  async fetchClearedUsers(params = {}) {
    const result = fetcher(
      '/games/select-farm/cleared-users',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async getGganbuRecommendations(params = {}) {
    const result = fetcher(
      '/games/gganbu/recommendations',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async acceptGganbuRequest(params = {}) {
    const result = fetcher(
      '/games/gganbu/accept-request',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async rejectGganbuRequest(params = {}) {
    const result = fetcher(
      '/games/gganbu/reject-request',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async registerBokMessage(params = {}) {
    const result = fetcher(
      '/games/gganbu/register-bok-message',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async requestGganbu(params = {}) {
    const result = fetcher('/games/gganbu/request', _TOKEN, 'PUT', params)
    return result
  }

  async breakEgg(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/break-egg/break', token, method, params)
    return result
  }

  async breakEggV2(token, method = 'PUT', params = {}) {
    const result = fetcher('/games/break-egg/break/v2', token, method, params)
    return result
  }

  async getEggData(params = {}) {
    const result = fetcher('/games/break-egg/egg', _TOKEN, 'GET', params)
    return result
  }

  async getMainEggData(params = {}) {
    const result = fetcher('/games/break-egg/main-egg', _TOKEN, 'GET', params)
    return result
  }

  async getEggDataByEggId(token, method = 'GET', params = {}) {
    const result = fetcher('/games/break-egg/egg-by-id', token, method, params)
    return result
  }
  async getEggListByEggIds(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/break-egg/egg-list-by-id',
      token,
      method,
      params,
    )
    return result
  }
  async getYutnoriPlayStatus(token, method = 'GET', params = {}) {
    const result = fetcher('/games/yutnori/play-status', token, method, params)
    return result
  }
  async getYutnoriPlayStatusV2(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/yutnori/play-status/v2',
      token,
      method,
      params,
    )
    return result
  }

  async playYutnori(token, method = 'POST', params = {}) {
    const result = fetcher('/games/yutnori/play-game', token, method, params)
    return result
  }
  async playYutnoriV2(token, method = 'POST', params = {}) {
    const result = fetcher('/games/yutnori/play-game/v2', token, method, params)
    return result
  }

  async assessAndGiveWater(token, method = 'GET', params = {}) {
    const result = fetcher(
      '/games/share-deal-item/water',
      token,
      method,
      params,
    )
    return result
  }

  async getEventPageMapping(params = {}) {
    const result = fetcher('/games/event-page-mapping', _TOKEN, 'GET', params)
    return result
  }

  async updateUserDealStatus(token, method = 'PUT', params = {}) {
    try {
      const options = {
        token,
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...params,
        }),
      }
      const BACK_URI = 'https://alwayzshop.ilevit.com'
      const res = await fetchWrapper(`${BACK_URI}/users/deal/status`, options)

      const data = await res.json()
      return data
    } catch (err) {
      console.log(err)
    }
  }

  async getChristmasTreeInviteUser(params = {}) {
    const result = fetcher(
      '/games/christmas-event/invite-user',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async clearChristmasTree(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/christmas-event/clear',
      token,
      method,
      params,
    )
    return result
  }

  async clearedBottomSheetPurchaseQuest(token, method = 'PUT', params = {}) {
    const result = fetcher(
      '/games/bottom-sheet-purchase-quest/cleared',
      token,
      method,
      params,
    )
    return result
  }

  async clearedBottomSheetPurchaseQuestFertilizer(
    token,
    method = 'PUT',
    params = {},
  ) {
    const result = fetcher(
      '/games/bottom-sheet-purchase-quest-fertilizer/cleared',
      token,
      method,
      params,
    )
    return result
  }

  async getHarvestOrderData(token, method = 'GET', params = {}) {
    const result = fetcher('/games/harvest-order-data', token, method, params)
    return result
  }

  async getDealEventUserData(params = {}) {
    const result = fetcher('/games/deal-event/user-data', _TOKEN, 'GET', params)
    return result
  }

  async getWaterRepoData(params = {}) {
    const result = fetcher('/games/water-repo/data', _TOKEN, 'PUT', params)
    return result
  }

  async clearDealEvent(params = {}) {
    let type
    if (type === 'clearDealEvent') return
    type = 'clearDealEvent'
    const result = fetcher('/games/deal-event/clear', _TOKEN, 'PUT', params)
    setTimeout(() => {
      type = false
    }, 500)
    return result
  }

  async exchangeLuckyBag(params = {}) {
    const result = fetcher(
      '/games/new-year-event-2024/exchange-lucky-bag',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async getUserAlphabetData(params = {}) {
    const result = fetcher(
      '/games/new-year-event-2024/alphabet',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async getNewYearEventHistory(params = {}) {
    const result = fetcher(
      '/games/new-year-event-2024/history',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async getAlphabetHistory(params = {}) {
    const result = fetcher(
      '/games/new-year-event-2024/alphabet-history',
      _TOKEN,
      'GET',
      params,
    )
    return result
  }

  async fetchPetInfoData(params = {}) {
    const result = fetcher('/games/pet/info', _TOKEN, 'GET', params)
    return result
  }

  async exchangeAlphabet(params = {}) {
    const result = fetcher(
      '/games/new-year-event-2024/exchange-alphabet',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async giveWaterToTeamAlfarm(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/games/team-alfarm/give-water',
      token,
      method,
      params,
    )
    return result
  }
  async getTeamAlfarmData(token, params = {}) {
    const result = await fetcher('/games/team-alfarm', token, 'GET', params)

    return result
  }
  async selectTeamAlfarmItemType(params = {}) {
    const result = await fetcher(
      '/games/team-alfarm/select-item',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async quitTeam(params = {}) {
    const result = await fetcher(
      '/games/team-alfarm/quit',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async updateResurrectBonusD1NudgeInfo(params = {}) {
    const result = await fetcher(
      '/games/update-resurrect-bonus-d1-nugde-info',
      _TOKEN,
      'PUT',
      params,
    )
    return result
  }

  async getNutrimentLeavesAdItemInfo(token, params = {}) {
    const result = await fetcher(
      '/games/nutriment-leaves/ad-data',
      token,
      'GET',
      params,
    )

    return result
  }
  async getNutrimentLeavesAdsInfo(token, params = {}) {
    const result = await fetcher(
      '/games/nutriment-leaves/ads-info',
      token,
      'GET',
      params,
    )

    return result
  }
  async getWinnerDealAdItemInfo(token, params = {}) {
    const result = await fetcher(
      '/games/winner-deal/ad-data',
      token,
      'GET',
      params,
    )

    return result
  }

  async getWinnerDealAdsInfo(token, params = {}) {
    const result = await fetcher(
      '/games/winner-deal/ads-info',
      token,
      'GET',
      params,
    )

    return result
  }

  async getWinnerDealAdsInfoV2(token, params = {}) {
    const result = await fetcher(
      '/games/winner-deal/ads-info/v2',
      token,
      'GET',
      params,
    )

    return result
  }

  async getBlackfridayDealItem(token, params = {}) {
    const result = await fetcher(
      '/games/blackfriday-deal/item',
      token,
      'POST',
      params,
    )
    return result
  }

  async recordSellerAdPromotingRewardLog(token, method = 'PUT', params = {}) {
    const result = await fetcher(
      '/games/seller-ad-promoting-event/record-reward',
      token,
      method,
      params,
    )
    return result
  }

  async setNewSellerAdPromotingEventLog(token, method = 'PUT', params = {}) {
    const result = await fetcher(
      '/games/seller-ad-promoting-event/set-new-log',
      token,
      method,
      params,
    )
    return result
  }

  async getRewardLogForSellerAdPromoting(token, method = 'GET', params = {}) {
    const result = await fetcher(
      '/games/seller-ad-promoting-event/reward-log',
      token,
      method,
      params,
    )
    return result
  }

  async getQuizBrowsingInfo(token, params = {}) {
    const result = await fetcher(
      '/games/quiz/browsing-info',
      token,
      'GET',
      params,
    )
    return result
  }

  async fightingGganbu(token, params = {}) {
    const result = await fetcher('/games/fighting-gganbu', token, 'PUT', params)
    return result
  }
}

export default new BackendApis()
