import { observable } from 'mobx'
import AB from '../utils/ab_v3'
import moment from 'moment'
import TimeStore from './TimeStore'
import AlwayzUserStore from './AlwayzUserStore'

const ABStore = observable({
  gifticonTester: false,
  teamAlfarmTest: false,
  pesticideRemoveTester: false,
  doubleExpEventTester: false,
  dietProjectTester: false,
  waterMaximumTester: false,
  googleAdTester: false,
  shortFormShopTester: false,
  winnerDealTripleExposure: false, // 오늘의특가 최대 노출량 3회 기작 -> 종료
  winnerDealDailyMaxExposureCount: 1,
  winnerDealTimeIntervalBetweenExposure: 6,
  nutrimentLeavesAdTripleExposure: false, // 낙엽 광고 최대 노출량 3회 기작 -> 종료
  leavesAdDailyMaxExposureCount: 2,
  leavesAdTimeIntervalBetweenExposure: 6,
  quizAdTripleExposure: false, // 퀴즈 광고 최대 노출량 3회 기작 -> 종료
  quizAdDailyMaxExposureCount: 2,
  quizAdTimeIntervalBetweenExposure: 6,
  eggAdTripleExposure: false, // 포춘쿠키 광고 최대 노출량 3회 기작 -> 종료
  eggAdDailyMaxExposureCount: 2,
  eggAdTimeIntervalBetweenExposure: 6,
  yutnoriTripleExposure: false, // 윷놀이 광고 최대 노출량 3회 기작 -> 종료
  yutnoriDailyMaxExposureCount: 1,
  yutnoriTimeIntervalBetweenExposure: 6,
  decreaseWinnerDealRewardTime: false,
  winnerDealRewardTime: 0.5,
  isSanSinEventTester: false,
  winnerDealRewardEnhanceTester: false,
  sellerFarmRewardEnhanceTester: false,
  eggRewardEnhanceTester: false,
  quizRewardEnhanceTester: false,
  floatingButtonTester: false,

  // testIDs
  testIds: [
    '6243835a9d22e940cecde8a5', //유성
    '627d10bfd430af8ffc940c7a', //지성
    '61a5a1313a1787613ebc7f2f', //소현
    '62271be8b3ef552fb2dd424d', //윤수
    '614b747d698b43323956cf2c', //시창
    '65eae5b80d0fb6902606916a', //유성 데브
    '626aa6a6c4d18f4110ecd6cb', //욱
    '65113f8c3dcc855d62e43a8b', //기훈
    '61bbe52d4c1a4b6cd086a13e', //경국
  ],

  initialize({ userId, data }) {
    const gifticonTesterResult = AB(userId, 'gifticonTester0226', [0])
    this.gifticonTester = gifticonTesterResult === 'b'

    const teamAlfarmTestResult = AB(userId, 'teamAlfarmTest', [100])
    this.teamAlfarmTest = teamAlfarmTestResult === 'b'

    const pesticideRemoveTesterResult = AB(userId, 'pesticideRemoveTester', [
      95,
    ])
    this.pesticideRemoveTester = pesticideRemoveTesterResult === 'b'

    const doubleExpTesterResult = AB(userId, 'doubleExpEventTest', [99])
    this.doubleExpEventTester = doubleExpTesterResult === 'b'

    const googleAdTesterResult = AB(userId, 'googleAdTester', [100])
    this.googleAdTester = googleAdTesterResult === 'b'

    const waterMaximumTesterResult = AB(userId, 'waterMaximumTester0311', [99])
    this.waterMaximumTester = waterMaximumTesterResult === 'b'
    if (TimeStore.currentMoment.isSameOrBefore(moment('2024-03-13'))) {
      this.waterMaximumTester = false
    }

    const shortFormShopTesterResult = AB(
      userId,
      'shortFormShopTest0627',
      [90, 95],
    )
    this.shortFormShopTester = shortFormShopTesterResult

    // 애드몹 보상형 광고 AB
    this.admobAlfarmTester = AB(userId, 'admobAlfarm0814', [95, 100]) === 'b'

    this.winnerDealTripleExposure =
      AB(userId, 'winnerDealTripleExposure0705', [50, 100]) === 'b'
    this.nutrimentLeavesAdTripleExposure =
      AB(userId, 'nutrimentLeavesAdTripleExposure0715', [50, 100]) === 'b'
    this.quizAdTripleExposure =
      AB(userId, 'quizAdTripleExposure0716', [50, 100]) === 'b'
    this.eggAdTripleExposure =
      AB(userId, 'eggAdTripleExposure0716', [50, 100]) === 'b'
    this.yutnoriTripleExposure =
      AB(userId, 'yutnoriTripleExposure0716', [50, 100]) === 'b'
    this.decreaseWinnerDealRewardTime =
      AB(userId, 'decreaseWinnerDealRewardTime0722', [100, 100]) === 'b' // 적용 받는 기작 지웠음 (진입점 이미지, 초)
    this.isSanSinEventTester =
      AB(userId, 'isSanSinEventTester0726', [50, 100]) === 'b'
    this.winnerDealRewardEnhanceTester =
      AB(userId, 'winnerDealRewardEnhanceTester0731', [90, 100]) === 'b' // 0813 전체 배포 완료
    this.sellerFarmRewardEnhanceTester =
      AB(userId, 'sellerFarmRewardEnhanceTester0812', [0, 100]) === 'b' // 08.16 전체 배포 완료
    this.eggRewardEnhanceTester =
      AB(userId, 'eggRewardEnhanceTester0813', [0, 100]) === 'b' // 08.16 전체 배포 완료
    this.quizRewardEnhanceTester =
      AB(userId, 'quizRewardEnhanceTester0814', [0, 100]) === 'b'
    this.floatingButtonTester =
      AB(userId, 'floatingButtonTest1003', [99]) === 'b' // 플로팅버튼(알림화분) 테스트

    // 강제 테스터
    if (this.testIds.includes(userId)) {
      this.gifticonTester = true
      // this.teamAlfarmTest = true
      // this.doubleExpEventTester = true
      this.googleAdTester = true
      this.admobAlfarmTester = true
      this.shortFormShopTester = 'c'
      this.winnerDealTripleExposure = true
      this.nutrimentLeavesAdTripleExposure = true
      this.quizAdTripleExposure = true
      this.eggAdTripleExposure = true
      this.yutnoriTripleExposure = true
      this.decreaseWinnerDealRewardTime = true
      // this.isSanSinEventTester = false
      this.winnerDealRewardEnhanceTester = true
      this.sellerFarmRewardEnhanceTester = true
      this.eggRewardEnhanceTester = true
      this.quizRewardEnhanceTester = true
      this.floatingButtonTester = true
    }
    // AB에 따라 영향 받는 변수들 스토어 내에서 세팅
    this.winnerDealDailyMaxExposureCount = this.winnerDealTripleExposure ? 3 : 1
    this.winnerDealTimeIntervalBetweenExposure = this.winnerDealTripleExposure
      ? 4
      : 3 // 기존에 3시간만 하단에 노출됐음. 2회 이상 가면 6으로 바꿔주기
    this.leavesAdDailyMaxExposureCount = this.nutrimentLeavesAdTripleExposure
      ? 3
      : 2
    this.leavesAdTimeIntervalBetweenExposure = this
      .nutrimentLeavesAdTripleExposure
      ? 4
      : 6
    this.quizAdDailyMaxExposureCount = this.quizAdTripleExposure ? 3 : 2
    this.quizAdTimeIntervalBetweenExposure = this.quizAdTripleExposure ? 4 : 6
    this.eggAdDailyMaxExposureCount = this.eggAdTripleExposure ? 3 : 2
    this.eggAdTimeIntervalBetweenExposure = this.eggAdTripleExposure ? 4 : 6
    this.yutnoriDailyMaxExposureCount = this.yutnoriTripleExposure ? 3 : 1
    this.yutnoriTimeIntervalBetweenExposure = this.yutnoriTripleExposure ? 4 : 6
    this.winnerDealRewardTime = this.decreaseWinnerDealRewardTime ? 0.25 : 0.5
  },
})

export default ABStore
