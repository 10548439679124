import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'
import ToastStore from 'store/ToastStore'
import backendApis from 'utils/backendApis'

const FloatingButtonNudgeModal = observer(({ token }) => {
  const [firstPage, setFirstPage] = useState(true)
  const [imageUri, setImageUri] = useState(
    'url(../icon/floatingButtonNudge/floatingNudgeModalOne.png)',
  )
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  useEffect(() => {
    const handleOverlayPermission = ({ data }) => {
      const parsedData = JSON.parse(data)
      if (parsedData?.type === 'checkOverlayPermission') {
        if (parsedData?.result === true) {
          // logger
          backendApis.logABclick(token, 'PUT', {
            location: 'nudgeModal',
            // 이미 백그라운드 권한이 있음
            action: 'overlayPermissionGranted',
            data: {
              description: 'permission already granted and button turned on',
              overlayRequestResult: parsedData?.result,
            },
          })

          ToastStore.toastOn({
            type: 'success',
            message: '알림화분이 생겼어요!',
            duration: 3000,
          })
          window.location.href = '#floatingButtonShow'
          ModalStore.setIsModalOpen('basic')
        } else {
          setFirstPage(false)
          setImageUri(
            'url(../icon/floatingButtonNudge/floatingNudgeModalTwo.png)',
          )
        }
      }

      if (parsedData?.type === 'overlayRequest') {
        const overlayRequestResult = parsedData?.result

        if (overlayRequestResult === true) {
          // logger
          backendApis.logABclick(token, 'PUT', {
            location: 'nudgeModal',
            // 백그라운드 권한이 없었음. 권한 새로 받음
            action: 'overlayPermissionGranted',
            data: {
              description: 'permission granted now and button turned on',
              overlayRequestResult: parsedData?.result,
            },
          })

          ToastStore.toastOn({
            type: 'success',
            message: '알림화분이 생겼어요!',
            duration: 3000,
          })
          window.location.href = '#floatingButtonShow'
          ModalStore.setIsModalOpen('basic')
        } else {
          // logger
          backendApis.logABclick(token, 'PUT', {
            location: 'nudgeModal',
            // 백그라운드 권한이 없었음. 권한 주는거 거절함.
            action: 'overlayPermissionRejected',
            data: {
              description: 'permission rejected',
              overlayRequestResult: parsedData?.result,
            },
          })

          ToastStore.toastOn({
            type: 'emoji',
            message: `권한을 허용하지 않으면 이용할 수 없어요`,
            emoji: '😢',
            duration: 1500,
          })
          ModalStore.setIsModalOpen('basic')
        }
      }
    }

    document.addEventListener('message', handleOverlayPermission)
    return () => {
      document.removeEventListener('message', handleOverlayPermission)
    }
  }, [])

  return (
    <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
      <div
        className='absolute flex flex-col justify-end items-center w-[90vw] h-[130vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 pb-[6vw]'
        style={{
          backgroundImage: imageUri,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'cover',
        }}
      >
        <button
          className='w-[70%] py-[14px] text-[#442B22] text-[5vw] font-bold rounded-3xl'
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          onClick={() => {
            if (firstPage) {
              // logger
              backendApis.logABclick(token, 'PUT', {
                location: 'nudgeModal',
                action: 'clickedFirstPageButton',
                data: {
                  description: 'clicked CTA button on the first intro page',
                },
              })

              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'checkOverlayPermission',
                }),
              )
            } else {
              // logger
              backendApis.logABclick(token, 'PUT', {
                location: 'nudgeModal',
                action: 'clickedSecondPageButton',
                data: {
                  description:
                    'clicked CTA button on the second permission page',
                },
              })

              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'requestOverlayPermission',
                }),
              )
            }
          }}
        >
          {firstPage && '네, 알려주세요!'}
          {!firstPage && '확인'}
        </button>
        {firstPage && (
          <button
            className='text-[3.8vw] py-[4px] w-[70%] text-[#736E64] mt-[2vw]'
            onClick={() => {
              // logger
              backendApis.logABclick(token, 'PUT', {
                location: 'nudgeModal',
                action: 'clickedFirstPageRejectButton',
                data: {
                  description: 'clicked reject button on the first intro page',
                },
              })

              ModalStore.setIsModalOpen('basic')
            }}
          >
            아니요, 괜찮아요
          </button>
        )}
        <button
          className=' z-[1101] w-[8%] top-[0vw] right-[4vw] absolute'
          onClick={() => {
            // logger
            backendApis.logABclick(token, 'PUT', {
              location: 'nudgeModal',
              action: 'clickedCloseButton',
              data: {
                description: 'clicked close button',
                page: firstPage ? 'firstPage' : 'secondPage',
              },
            })

            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
      </div>
    </div>
  )
})

export default FloatingButtonNudgeModal
